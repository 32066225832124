import styled from 'styled-components';
import { GREYS } from '../../globals/colours';
import { HMI } from 'CORE__UI/apps/HamburgerApp/core__HamburgerMenu';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
const HMI_EX = styled(HMI)`
  color: ${GREYS.white};
  font-size: 26px;
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 19px;
    margin-right: 6px;
  }
`;

export { HMI_EX as HMI };
