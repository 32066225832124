import React from 'react';
import PropTypes from 'prop-types';
import { HMI } from 'UI/apps/HamburgerMenuApp/HamburgerMenu';
import { handleBackNavigation } from 'Services/global/core__handleBackNavigation';

/**
 * this component contains the HamburgerMenu
 * @param {*} openCategoryMenu Function to trigger left side bar open and close
 */
const HamburgerMenu = ({ openCategoryMenu, showCrossIcon }) => {
  return (
    <React.Fragment>
      {showCrossIcon ? (
        <HMI
          onClick={() =>
            handleBackNavigation(window.sessionStorage.getItem('root'))
          }
        >
          &#10005;
        </HMI>
      ) : (
        <HMI id="hamburger" onClick={openCategoryMenu}>
          &#9776;
        </HMI>
      )}
    </React.Fragment>
  );
};
HamburgerMenu.propTypes = {
  openCategoryMenu: PropTypes.func,
  showCrossIcon: PropTypes.bool,
};

export default HamburgerMenu;
