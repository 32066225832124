import styled from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import { FONT } from 'UI/globals/colours';

export const HMI = styled.span`
  color: ${FONT.light};
  display: none;
  @media (max-width: ${BREAKPOINT_S}) {
    display: block;
    font-size: 20px;
    margin-right: 16px;
    cursor: pointer;
  }
`;
