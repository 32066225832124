import React from 'react';
import PropTypes from 'prop-types';
import { HamburgerMenu } from './components/HamburgerMenu';

// Function to trigger left side bar open and close
const HamburgerApp = ({ appConfig }) => {
  const openCategoryMenu = () => {
    // getting the width of the screen
    try {
      // Checking the main-left-wrapper class existence
      if (document.querySelector('.main-left-wrapper')) {
        //moving on the top of the page after returning
        const getScrollId = document.querySelector('#left-wrapper');
        if (getScrollId !== null) {
          getScrollId.scrollTop = 0;
        }
        // removing the active class from main-left-wrapper
        if (document.querySelector('.ml-active')) {
          document
            .querySelector('.main-left-wrapper')
            .classList.remove('ml-active');
          if (document.querySelector('#toplevel')) {
            document.querySelector('body').classList.add('mb-hide');
          }
        } else {
          // adding the active class to main-left-wrapper
          document
            .querySelector('.main-left-wrapper')
            .classList.add('ml-active');
          if (document.querySelector('#toplevel')) {
            document.querySelector('body').classList.add('mb-hide');
          }
          document.querySelector('.main-left').style.width = `320px`;
          if (document.getElementById('main-promo-wrapper')) {
            document.getElementById('main-promo-wrapper').style.display =
              'block';
          }
        }
      }
      //
    } catch (error) {
      return error;
    }
  };
  return (
    <HamburgerMenu
      openCategoryMenu={openCategoryMenu}
      showCrossIcon={appConfig?.showcrossicon}
    />
  );
};
HamburgerApp.propTypes = {
  appConfig: PropTypes.object,
};
export default HamburgerApp;
