import React from 'react';
import ReactDOM from 'react-dom';

import ScDecoder from '../../../services/json/core__decoder';
import createStore from './core__hamburger-menu.store';
import { Provider as ReduxProvider } from 'react-redux';
import { connect } from 'react-redux';
import HamburgerApp from './component/core__hamburger-menuReactApp';

class HamburgerMenuApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) return;
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp();
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance appConfig={this.appConfig} />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    const mapStateToProps = () => ({
      appConfig: this.appConfig,
    });

    const mapDispatchToProps = {
      url: this.url,
    };

    HamburgerApp.serverFetch = {
      createStore: createStore,
      fetchData: () => dispatch => {
        return new Promise(resolve => {
          dispatch({ type: 'DO_NOTHING' });
          resolve();
        });
      },
    };

    return connect(mapStateToProps, mapDispatchToProps)(HamburgerApp);
  }
}

export default HamburgerMenuApp;
